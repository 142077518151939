button.fab-button {
    left: 68%;
    right: unset;
}

@media (max-width: 1279px) {
    button.fab-button {
        left: 77%;
        right: unset;
    }
}

@media (max-width: 959px) {
    button.fab-button {
        right: 16.666666%;
        left: unset;
    }
}

@media (max-width: 599px) {
    button.fab-button {
        right: 15px;
        left: unset;
    }
}
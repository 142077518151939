.tourtle-steps-container {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding: 0 .5rem;
    padding-top: 15px;
}

.tourtle-step {
    &__image-container {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    &__fullscreen {
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color:white;
        border-radius: 50%;
        z-index: 10;
        color: $gray-600;
        cursor: pointer;
    }

    &__video-container .tourtle-step__fullscreen {
        bottom: 20px;
    }

    &__image {
        border-radius: $border-radius;
        max-width: 100%;
        max-height: 350px;
        text-align: center;
        display: block;
        margin: 0 auto;
    }
}

.step-item {
    $self: &;

    cursor: pointer;

    sub, sup {
        line-height: 0;
    }

    &#{&} {
        // outline: 4px solid red;
        margin-bottom: 0;

        #{$self}__wrapper {
            width: 100%;
            position: relative;
            max-height: unset;
            padding: 0;

            &.with-image {
                width: calc(100% - 126px);
            }
        }

        #{$self}__content {
            height: 100%;
            width: 100%;
        }

        #{$self}__title {
            font-size: $font-small;
            font-weight: 700;
            margin-bottom: .25rem;
            max-width: calc(100% - 1.5rem); // Width of all the controls
        }

        #{$self}__description {
            font-size: $font-smaller;
            line-height: 1rem;
            // max-height: 80px;
            max-width: calc(100% - 2rem);

            * {
                font-size: $font-smaller !important;
            }

            &-blocked {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;

                p, ul {
                    color: $black;
                    margin: 0;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    list-style: disc;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    li {
                        margin-left: 2rem;
                    }
                }
            }
        }

        #{$self}__card {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            
            &-inner {
                min-height: calc(75px);
                display: flex;
                flex-wrap: nowrap;
                // align-items: center;
                gap: 1rem;
                padding: .75rem;
            }
        }

        #{$self}__thumb {
            display: block;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            margin: -0.25rem;
            width: 120px;
            height: 120px;
        }

        #{$self}__badge {
            position: absolute;
            bottom: -.25rem;
            right: 0;
            background-color: unset;
            height: unset;
            border-radius: unset;
            color: #707070;
            padding: 0;
            
            span {
                padding: 0;
            }
        }

        #{$self}__controls {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: .75rem;
        }

        #{$self}__edit {
            position: relative;
            height: 100%;
            bottom: unset;
            width: unset;
            margin-right: -.4rem;

            span {
                padding: 0;
            }
        }

        #{$self}__checkbox {
            position: relative;
            margin-left: unset;
            margin-right: .25rem;

            span {
                padding: 0;
            }
        }
    }
}

.tourtle-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding: 0 .5rem;
}

.tourtle-item {
    &__title {
        &#{&} {
            color: $black;
            line-height: $font-medium;
            font-size: $font-small;
            font-weight: $font-weight-dark;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }

    &-favorite {
        height: 20px !important;
    }
    &-card {
        &#{&} {
            filter: unset;
            box-shadow: none;
            overflow: unset;
        }
    }

    &__wrapper {
        cursor: pointer;
        padding: .75rem;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
    }

    &__badge {
        &#{&} {
            bottom: 0;
            right: 0;
            background-color: unset;
            height: unset;
            border-radius: unset;
            color: #707070;
            padding: 0;

            span {
                padding: 0;
            }
        }
    }

    &__inner {
        &#{&} {
            display: flex;
            gap: 1rem;
            padding-bottom: unset !important;
            padding: 0;
        }
        // flex-wrap: nowrap;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;
        flex-grow: 1;
        margin-top: -0.25rem;
        width: calc(100% - 120px - 1rem); // 100% - thumbnail - flex-gap
    }

    &__actions {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        // display: none; // TODO: change

        &__interactive {
            margin-left: auto;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row-reverse;
            align-items: center;
            gap: .5rem;

            button {
                padding: 0;
            }
        }
    }


    filter: drop-shadow(rgba(0, 0, 0, 0.15) 0 3px 4px);
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    overflow: visible !important;
}

.tourtle-item-menu-button {
    // position: absolute !important;
    margin: 0 !important;
}

// .tourtle-item-badge-index {
//     position: absolute;
//     float: right;
//     top: 0px;
//     right: 0px;
//     font-size: 12px !important;
//     height: 20px !important;
//     border-radius: 0px !important;
// }

.tourtle-item-thumbnail {
    position: relative;
    height: 120px;
    width: 120px;
    // background-color: rgba($black, .2);
    border-radius: $border-radius;
    margin: -0.25rem;

    &__landing {
        height: 75px;
        width: 75px;

        &__image {
            display: block;
            border-radius: $border-radius;
            background-color: rgba($black, .1);
            box-shadow: $box-shadow-sm;
            min-width: 75px;
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;

            &.with-overlay {
                filter: brightness(.8);
            }
        }
    }


    &__image {
        display: block;
        border-radius: $border-radius;
        background-color: rgba($black, .1);
        box-shadow: $box-shadow-sm;
        min-width: 120px;
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;

        &.with-overlay {
            filter: brightness(.8);
        }
    }

    &__centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
    }

    &__icon {
        &#{&} {
            height: 2.5rem;
            width: 2.5rem;
        }
    }

    &__text {
        &#{&} {
            font-size: $font-regular;
        }
    }

    &__badge {
        &#{&} {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }
}

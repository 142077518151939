.tourtle-info {
    &#{&} {
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
        padding: 0.5rem 0.75rem;

        .tourtle-image-container {
            display: block;
            // outline: 4px solid red;
        }

        .tourtle-new-description {
            p {
                font-size: 0.9rem;
            }
        }
    }

    &__image-container {
        width: 100%;
        float: left;
        position: relative;
        overflow: hidden;

        &__clickable {
            cursor: pointer;
        }
    }
    
    &__fullscreen {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color:white;
        border-radius: 50%;
        z-index: 10;
        color: $gray-600;
        cursor: pointer;
    }

    &__video-container .tourtle-step__fullscreen {
        bottom: 20px;
    }

    &__image {
        width: 100%;
        background-color: $gray-300;
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
    }

    &__thumb {
        max-height: 200px;
        max-width: 100%;
        position: relative;
        display: block;
        text-align: center;
        margin: 0 auto;
        background-color: $gray-300;
    }

    &__author {
        width: 100%;

        .tourtle-info-typography,
        .tourtle-visibility-text {
            font-size: $font-tiny !important;
            line-height: 1rem;
        }
        .tourtle-username {
            font-size: $font-smaller;
            color: $primary;
            text-decoration: underline;
        }
    }

    &__source {
        font-size: $font-tiny;
        padding: 0 1.25rem;
        margin-bottom: 0.75rem !important;
    }
}

.tourtle-description-block {
    padding: 0 !important;

    p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        min-height: 1rem;
    }

    .tourtle-new-description {
        color: $black;
        padding: 0 0.75rem;
        font-size: $font-small;
        line-height: 1rem;
    }
}

.ril {
    &__caption {
        font-family: 'Roboto';
        background-color: #FF000000 !important;
        padding: 10px 20px;

        .ril-caption-content {
            padding: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
    &__toolbar {
        font-family: 'Roboto';
        background-color: #FF000000 !important;
    }
}

.ril-outer {
    .ril-prev-button,
    .ril-next-button,
    .ril-toolbar-left,
    .ril-zoom-in,
    .ril-zoom-out,
    .ril-caption {
        transition: opacity 0.3s, visibility 0.3s;
        transition-delay: visibility 0.3s;

        &.hidden-controls {
            visibility: hidden;
            opacity: 0;
        }
    }

    .video-react.video-react-fluid {
        --screen-width-percentage: 0.8;
        --offset: 10rem;
        
        // 16:9 video
        width: calc((100vw * var(--screen-width-percentage)) - var(--offset));
        padding-top: calc((56.25vw * var(--screen-width-percentage)) - (var(--offset) * 0.5))!important;
        top: 50%;
        transform: translateY(-50%);

        .video-react-big-play-button {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media screen and (max-width: 960px) {
    .ril-outer .video-react.video-react-fluid {
        --screen-width-percentage: 0.9;
        --offset: 5rem;
    }
}


@media screen and (max-width: 540px) {
    .ril-outer .video-react.video-react-fluid {
        --screen-width-percentage: 0.95;
        --offset: 1.5rem;
    }
}
.tourtle-container-root {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

:root {
    --vh: 1vh;
}

.tourtle-container-root .react-swipeable-view-container {
    min-height: 100vh;
    max-height: 100vh;
    
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);    
}

.tourtle-container-stop-scroll {
    position: fixed !important;
}

.tourtle-root {
    overflow-x: hidden;
    flex: 1;
    height: 100%;
    padding-top: 75px;
}

.tourtle-padding {
    padding: 0 16px;
}

.tourtle-card {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.tourtle-list-container {
    display: flex;
    justify-content: center;
}

.tourtle-info-block {
    position: relative;
    width: 100%;
    padding-top: 15px;
    padding-left: 9px;
    padding-right: 9px;
    background-color: white;
    float: left;
}

.tourtle-description {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
    text-decoration: none;
    margin-bottom: 0px !important;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    max-height: calc(5rem);
    overflow: hidden;
    display: -webkit-box !important;
    white-space: normal;
    height: auto;
    padding: 0px;
    margin: 0px !important;
}

.tourtle-description-full {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
    text-decoration: none;
    margin-bottom: 0px !important;
}

.tourtle-description-extra {
    -webkit-line-clamp: unset !important;
    text-overflow: clip !important;
}

.tourtle-info-typography {
    font-size: 0.7rem !important;
}

.tourtle-author-block {
    float: right;
    width: 50%;
    height: 100%;
    position: relative;
    padding-left: 10px;
}

.tourtle-visibility-container {
    margin-top: 1px;
    margin-bottom: 1px;
}

.tourtle-visibility-icon {
    font-size: 18px !important;
    float: left;
    margin-right: 2px;
}

.tourtle-visibility-text {
    font-size: 0.8rem !important;
    display: inline-block !important;
}

.tourtle-rating {
    margin-top: 20px;
}

.tourtle-read-more {
    display: flex;
    align-items: center;
    margin-top: 12px;
    justify-content: center;
}

/* .tourtle-description-block {
    width: 100%;
    padding-top: 20px;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 20px;
    background-color: white;
    float: left;
    word-break: break-word;
}

.tourtle-description-block p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    min-height: 0.9rem;
} */

.public-DraftStyleDefault-block {
    margin: 0px !important;
}

.tourtle-steps-block {
    position: relative;
}

.tourtle-steps-block p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    min-height: 24px;
}

.tourtle-number-of-steps {
    margin-top: 20px;
    padding-bottom: 30px;
    text-align: center;
}

.tourtle-grid-list {
    width: 100%;
}

.tourtle-grid-list-tile {
    width: 100%;
    margin-bottom: 20px;
}

.tourtle-add-step-container {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
}

.tourtle-add-step-container.pt-0 {
    padding-top: 0;
}

.tourtle-add-step-container-top {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
}

.tourtle-add-step-container-top.pt-0 {
    padding-top: 0;
}


.tourtle-add-first-step {
    padding-top: 15px;
}

.tourtle-add-step-front {
    padding: 0;
    text-align: left;
    padding-top: 10px;
    width: 100%;
    margin-bottom: 0px;
}

.tourtle-card-content {
    padding-bottom: 16px !important;
}

.tourtle-second-card {
    opacity: 0.5;
    cursor: default;
    width: 100%;
    margin-top: 20px;
}

.tourtle-third-card {
    opacity: 0.1;
    cursor: default;
    width: 100%;
    margin-top: 20px;
}

.tourtle-add-icon {
    color: #00cc99;
    float: left;
}

.tourtle-add-multiple-icon {
    color: #00cc99;
    float: left;
    transform: rotate(180deg);
}

.tourtle-link-to-other-icon {
    color: #00cc99;
    float: left;
    transform: rotate(90deg);
}

.tourtle-bottom-container {
    display: block;
    position: relative;
    margin-top: 10px;
    padding-bottom: 60px;
}

.tourtle-comment-section {
    padding: 15px;
}

.tourtle-comments {
    margin-top: 10px;
    margin-bottom: 40px;
}

.tourtle-audio-player {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.tourtle-divider {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.tourtle-button-more {
    display: flex !important;
    text-transform: none !important;
    margin: 5px auto 5px auto !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.tourtle-button-reply {
    display: flex !important;
    text-transform: none !important;
    padding: 0 10px 0 0 !important;
    margin: 0px auto 10px auto !important;
}

.tourtle-desc-divider {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.tourtle-old-description {
    display: block;
    margin-top: 1em;
    font-size: 0.9rem;
}

.tourtle-new-description {
    display: block;
    height: 100%;
    font-size: 0.9rem;
}

.tourtle-new-description p {
    white-space: pre-wrap;
}

.step-description p {
    white-space: pre-wrap;
}

.tourtle-button-container {
    position: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-content: center;
    text-align: -webkit-center;
}

.tourtle-add-comment-button {
    text-transform: none !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 15px !important;
}

.tourtle-jump-to {
    margin-bottom: 15px !important;
    text-transform: none !important;
}

.tourtle-extra-margin {
    margin-top: 10px;
}

.tourtle-jump-to-container {
    padding: 0 10px;
    text-align: right;
    padding-top: 10px;
    width: 100%;
}

.tourtle-thumb {
    max-height: 200px;
    max-width: 100%;
    position: relative;
    display: block;
    text-align: center;
    margin: 0 auto 0 auto;
}

.tourtle-image-container {
    width: 50%;
    float: left;
    background-color: #e6e6e6;
}

.tourtle-chip-container {
    padding: 0 1.25rem;
}

.tourtle-chip {
    margin-right: 5px;
    margin-bottom: 5px;
}

.tourtle-username {
    cursor: pointer;
    color: #0000ff;
}

.tourtle-star-rating {
    display: block;
    float: left;
}

.tourtle-number-rating {
    margin-top: 2px !important;
    display: block !important;
    float: left !important;
    color: grey !important;
    font-size: 10px !important;
}

.tourtle-list-sub-header {
    margin-top: -15px !important;
    margin-bottom: -10px !important;
}

.tourtle-list-item-icon-container {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.tourtle-list-item-icon-container-last {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
}

.tourtle-collaborators {
    font-size: 18px !important;
    margin-left: 2px;
    margin-right: 4px;
    margin-bottom: -4px;
    cursor: pointer;
}

.tourtle-step-navigation {
    position: absolute !important;
    top: 55px;
    height: 60px;
    width: 100%;
    text-align: center;
    z-index: 10;
    transition: 0.4s ease all;
    background-color: white;
}

.tourtle-step-navigation-shown {
    opacity: 1;
}

.tourtle-step-navigation-hidden {
    opacity: 0;
    pointer-events: none;
}

.tourtle-share-button {
    font-size: 24px !important;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    vertical-align: middle;
    margin: 5px;
}

.step__checkbox {
    margin: 0 !important
}

.tourtle-jump-button {
    vertical-align: middle;
    font-size: 24px !important;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    margin: 5px;
}

.tourtle-arrow-left {
    vertical-align: middle;
    font-size: 32px !important;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
}

.tourtle-arrow-right {
    vertical-align: middle;
    font-size: 32px !important;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
}

.tourtle-step-navigation-badge {
    margin: 15px auto !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    height: 30px !important;
}

.tourtle-step-navigation-add-icon {
    color: #00cc99;
    margin: 5px 5px 5px 0px;
    cursor: pointer;
    vertical-align: middle;
}

.tourtle-step-navigation-edit-icon {
    color: rgba(0, 0, 0, 0.54);
    margin: 5px;
    cursor: pointer;
    vertical-align: middle;
}

.tourtle-step-navigation-delete-icon {
    color: rgba(0, 0, 0, 0.54);
    margin: 5px;
    cursor: pointer;
    vertical-align: middle;
}

.tourtle-step-navigation-remove-linked-icon {
    color: rgba(0, 0, 0, 0.54);
    margin: 5px 5px 5px 39px;
    cursor: pointer;
    vertical-align: middle;
}

.view-history-card {
    max-height: 80vh;
    overflow: auto!important;
}

.view-history-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-history-dialog {
    .MuiDialog-paper-243 {
        margin: 24px;
    }
    .MuiDialog-paper-237 {
        margin: 24px;
    }
}

.view-history-content-container {
    padding: 10px !important;
}

.view-history-content {
    list-style: none;
    padding-left: 0;
    /* width: calc(100vw - 144px); */
    /* max-width: 500px; */
}

.view-history-content li {
    display: flex;
    gap: 0.5rem;
}

.view-history-content li .edit-desc {
    flex-shrink: 0;
    min-width: 33%;
    font-weight: 500;
    font-size: 0.75rem;
}

.view-history-content li .user {
    /* flex-shrink: 0; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    font-size: 0.75rem;
}

.view-history-content li .date {
    flex-shrink: 0;
    min-width: 33%;
    /* margin-left: auto; */
    font-size: 0.75rem;
    margin-right: 0.5rem;
}

@media screen and (max-width: 479px) {
    .view-history-content li .user,
    .view-history-content li .date {
        min-width: 0;
    }
}

.manage {
    margin: 10px !important;
    padding: 5px 5px !important;
    font-size: 0.8rem !important;
}